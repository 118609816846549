import { useRouter } from 'next/router'

// Libs
import { useAppSelector } from '@/redux/hooks'

// Constants
import { LOCALE } from '@/constants/defaultLocale'

const useI18n = () => {
  const { locale } = useRouter()

  const isChinese = locale === LOCALE.ZH_TW || locale === LOCALE.ZH_HK

  const isOverseaUser = useAppSelector((state) => state.common.isOverseaUser)

  return { isOverseaUser, isChinese }
}

export default useI18n
