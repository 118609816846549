import classnames from 'classnames/bind'
import { useTranslation } from 'next-i18next'

// Libs
import { handleGtmSocialChannel } from '@/methods/handleGtmEvent'
import useI18n from '@/hooks/useI18n'

// Components
import Link from '@/components/common/Link'
import Icon from '@/assets/icons'
import { GTM_LOCATION, GtmSocialChannelChannel } from '@/constants/gtmEvent'

// Constants
import { EXTERNAL_LINK } from '@/constants/externalLink'

// Styles
import styles from './style.module.scss'

// Variables
const cx = classnames.bind(styles)

// Interfaces
interface IProps {
  handleDropdownMenuClose: () => void
}

function SocialMediaList(props: IProps) {
  const { handleDropdownMenuClose } = props
  const { t } = useTranslation()
  const { isOverseaUser } = useI18n()

  return (
    <nav className={cx('social-media-list')}>
      <div className={cx('social-media-list__title')}>{t('Common_Follow_Us')}</div>
      <ul className={cx('social-media-list__list')}>
        <li className={cx('social-media-list__item')}>
          <Link
            handleClick={() => {
              handleDropdownMenuClose()
              handleGtmSocialChannel({ socialChannel: GtmSocialChannelChannel.FACEBOOK, location: GTM_LOCATION.HEADER_MENU })
            }}
            url='https://www.facebook.com/tutorabcshop'
            isOpen
          >
            <Icon.Facebook />
          </Link>
        </li>
        <li className={cx('social-media-list__item')}>
          <Link
            handleClick={() => {
              handleDropdownMenuClose()
              handleGtmSocialChannel({ socialChannel: GtmSocialChannelChannel.LINE, location: GTM_LOCATION.HEADER_MENU })
            }}
            url={isOverseaUser ? EXTERNAL_LINK.CUSTOMER_SERVICE_CENTER_HK : EXTERNAL_LINK.CUSTOMER_SERVICE_CENTER}
            isOpen
          >
            <Icon.MenuLine />
          </Link>
        </li>
        <li className={cx('social-media-list__item')}>
          <Link
            handleClick={() => {
              handleDropdownMenuClose()
              handleGtmSocialChannel({ socialChannel: GtmSocialChannelChannel.INSTAGRAM, location: GTM_LOCATION.HEADER_MENU })
            }}
            url='https://instagram.com/tutorabcshop'
            isOpen
          >
            <Icon.Instagram />
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default SocialMediaList
