export const QUERY_KEY = {
  FETCH_BANNER_LIST: 'fetchBannerList',
  FETCH_LIVE_LIST: 'fetchLiveList',
  FETCH_MEMBER_DATA: 'fetchMemberData',
  FETCH_ROOM_LIVING_DATA: 'fetchRoomLivingData',
  FETCH_ROOM_DATA: 'fetchRoomData',
  FETCH_PRODUCT_DATA: 'fetchProductData',
  FETCH_PRODUCT_LIST: 'fetchProductList',
  FETCH_CATEGORY_LIST: 'fetchCategoryList',
  FETCH_LIVE_INFO: 'fetchLiveInfo',
  FETCH_PLAYBACK_INFO: 'fetchPlaybackInfo',
  FETCH_CATEGORY_FILTER: 'fetchCategoryFilter',
  FETCH_USER_AUTH: 'fetchUserAuth',
  FETCH_REFRESH_TOKEN: 'fetchRefreshToken',
  FETCH_ROOM_PRODUCT_DATA: 'fetchRoomProductData',
  FETCH_MERCHANT_INFO: 'fetchMerchantInfo',
  FETCH_CART_ID: 'fetchCartId',
  FETCH_CART_DATA: 'fetchCartData',
  UPDATE_CART_DATA: 'updateCartData',
  FETCH_PRECART_LIST: 'fetchPreCartList',
  FETCH_MERCHANTS_INFO: 'fetchMerchantsInfo',
  FETCH_USER_PROFILE: 'fetchUserProfile',
  FETCH_REGION_DATA: 'fetchRegionData',
  FETCH_PAYMENT_METHOD: 'fetchPaymentMethod',
  FETCH_DELIVERY_METHOD_LIST: 'fetchDeliveryMethodList',
  FETCH_CREATE_ORDER: 'fetchCreateOrder',
  FETCH_MEMBER_LIVE_LIST: 'fetchMemberLiveList',
  FETCH_CURRENT_LIVE_LIST: 'fetchCurrentLiveList',
  FETCH_FUTURE_LIVE_LIST: 'fetchFutureLiveList',
  FETCH_PAST_LIVE_LIST: 'fetchPastLiveList',
  REMOVE_ROOM_PRODUCT_DATA: 'removeRoomProductData',
  FETCH_ORDER_PRE_CART_AMOUNT: 'fetchOrderPreCartAmount',
  UPDATE_MEMBER_DATA: 'updateMemberData',
  FETCH_ORDER_LIST: 'fetchOrderList',
  FETCH_ORDER_DATA: 'fetchOrderData',
  FETCH_CHECKING_ORDER_LIST: 'fetchCheckingOrderList',
  FETCH_CHECKING_ORDER_DATA: 'fetchCheckingOrderData',
  FETCH_RETURNED_ORDER_LIST: 'fetchReturnedOrderList',
  FETCH_RETURNED_ORDER_DATA: 'fetchReturnedOrderData',
  FETCH_EXTRA_MEMBER_DATA: 'fetchExtraMemberData',
  DELETE_ORDER: 'deleteOrder',
  FETCH_DONATION_INVOICE_LIST: 'fetchDonationInvoiceList',
  FETCH_PROMOTION_TICKET_LIST: 'fetchPromotionTicketList',
  SET_PROMOTION_TICKET: 'setPromotionTicket',
  FETCH_PROMOTION_PRICE_RANGE: 'fetchPromotionPriceRange',
  FETCH_THEME_PRODUCT_LIST: 'fetchThemeProductList',
  FETCH_THEME_LIST: 'fetchThemeList',
  FETCH_SEARCH_DATA: 'fetchSearchData',
  FETCH_SEARCH_HOT_KEY: 'fetchSearchHotKey',
  FETCH_SEARCH_HOT_KEY_LIVE: 'fetchSearchHotKeyLive',
  FETCH_LIVE_ROOM_LIST_BY_PRODUCT_ID: 'fetchLiveRoomListByProductId',
  FETCH_INVOICE_DATA: 'fetchInvoiceData',
  FETCH_RECENT_LIVE_STREAMING_PRODUCT_LIST: 'fetchRecentLiveStreamingProductList',
  FETCH_PRODUCT_LIVE_DATE: 'fetchProductLiveDate',
  FETCH_PLATFORM_ANNOUCEMENTS: 'fetchPlatformAnnouncements',
  FETCH_STRAWBERRY_TOKEN: 'fetchStrawberryToken',
  FETCH_CATEGORY_PRODUCT_LIVE_TIME: 'fetchCategoryProductLiveTime',
  FETCH_BANK_INFO_DATA: 'fetchBankInfoData',
  FETCH_RECOMMEND_MERCHANTS: 'fetchRecommendMerchants',
  FETCH_PCHOME_PRODUCT_DATA: 'fetchPchomeProductData',
  FETCH_PCHOME_TOKEN: 'fetchPchomeToken',
  FETCH_POPULAR_PRODUCTS: 'fetchPopularProducts',
  FETCH_POPULAR_PRODUCTS_FROM_MALL: 'fetchPopularProductsFromMall',
  FETCH_PLATFORM_SETTING: 'fetchPlatformSetting',
  FETCH_EVENT_NAME: 'fetchEventName',
}
