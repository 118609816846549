import Image from 'next/image'

// Components

// Constants
import { imageDomain } from '@/constants/imageDomain'
import { basePath } from '@/constants/routePath'

// Styles

// Variables

// PropTypes
export enum PlaceholderType {
  empty = 'empty',
  blur = 'blur',
}

type ImageProps = {
  alt: string
  src?: string
  width?: number
  height?: number
  priority?: boolean
  blurDataURL?: string
  sizes?: string
  lazyBoundary?: string
  quality?: number
  onLoadingComplete?: () => void
  fill?: boolean
  style?: object
  placeholder?: PlaceholderType
}

function ImageComponent(props: ImageProps) {
  const { alt, width, height, src, priority, sizes, lazyBoundary, quality, fill, placeholder = PlaceholderType.empty, style, ...restProps } = props

  // 因為目前機器是對內，url 來的圖片幾乎是對外
  // 如果不在 imageDomain 裡，就先不用 next/image
  // TODO: 還要再有沒有辦法從 next 解決，或是讓機器對外
  const isFromUrl = Boolean(src?.match(/^(http|https):/))
  const isAllowDomain = imageDomain.some((item) => src?.includes(item))

  const shouldNativeImage = isFromUrl && !isAllowDomain

  // TODO: 如果到時有就瀏覽器圖片跑版的問題，要用 userAgentType 來判斷是否走 unoptimized，可以參考大人官網
  // 因為 shop 用 Nextjs 13，想說試看看會不會官方已經處理
  // const isUnoptimized = userAgentType === 'ancient' || shouldNativeImage
  const isUnoptimized = shouldNativeImage

  const newImageSrc = isFromUrl ? src : `${basePath}${src}`

  return (
    <>
      {newImageSrc && (
        <Image
          alt={alt}
          width={width}
          height={height}
          src={newImageSrc}
          priority={priority}
          fill={fill}
          sizes={sizes}
          placeholder={placeholder}
          lazyBoundary={lazyBoundary}
          unoptimized={isUnoptimized}
          quality={quality || 75}
          style={style}
          {...restProps}
        />
      )}
    </>
  )
}

// ImageComponent.defaultProps = defaultProps

export default ImageComponent
