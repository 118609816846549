// 順序看 LOCALE!!! LANGUAGE_LIST 是用因為 Object.values(LOCALE)
const LOCALE = {
  ZH_TW: 'zh-tw',
  ZH_HK: 'zh-hk',
  EN_US: 'en-us',
  EN_HK: 'en-hk',
  KO_KR: 'ko-kr',
  // EN_HK: 'en-hk',
  // TR_TR: 'tr-tr',
  // ID_ID: 'id-id',
  // ES_PE: 'es-pe',
  // ES_ES: 'es-es',
  // IT_IT: 'it-it',
  // JA_JP: 'ja-jp',
  // PT_BR: 'pt-br',
  SA_IN: 'sa-in', // 測試用，value 故意給語言包的 key
}

// 給 moment 的 locale 用
const LOCALE_CODE = {
  [LOCALE.ZH_TW]: 'zh-tw',
  // [LOCALE.EN_US]: 'en',
  [LOCALE.ZH_HK]: 'en-gb',
  [LOCALE.EN_HK]: 'en-hk',
  [LOCALE.KO_KR]: 'ko-kr',
  // [LOCALE.EN_HK]: 'en-gb',
  // [LOCALE.TR_TR]: 'tr',
  // [LOCALE.ID_ID]: 'id',
  // [LOCALE.ES_PE]: 'es',
  // [LOCALE.ES_ES]: 'es',
  // [LOCALE.IT_IT]: 'it',
  // [LOCALE.JA_JP]: 'ja',
  // [LOCALE.PT_BR]: 'pt',
  [LOCALE.SA_IN]: 'zh-tw', // 測試用，先給 zh-tw
}

const LOCALE_LABEL = {
  [LOCALE.ZH_TW]: '繁體中文',
  [LOCALE.ZH_HK]: '繁體中文 (香港)',
  [LOCALE.EN_US]: 'English',
  [LOCALE.EN_HK]: 'English (Hong Kong)',
  [LOCALE.KO_KR]: 'English ( Korea )',
  // [LOCALE.EN_HK]: 'English (Hong Kong)',
  // [LOCALE.TR_TR]: 'Türkçe',
  // [LOCALE.ID_ID]: 'Bahasa Indonesia',
}

// const chineseLocale = [LOCALE.ZH_TW, LOCALE.ZH_HK]
const chineseLocale = [LOCALE.ZH_TW]

const LANGUAGE_LIST = Object.values(LOCALE)
  .map((item) => {
    return {
      label: LOCALE_LABEL[item],
      value: item,
    }
  })
  .filter((item) => item.label && item.label.length > 0)

const DEFAULT_LOCALE = LOCALE.EN_US

const allowLocale = [
  'default',
  LOCALE.ZH_TW,
  LOCALE.EN_US,
  LOCALE.ZH_HK,
  LOCALE.EN_HK,
  LOCALE.KO_KR,
  // LOCALE.EN_HK,
  // LOCALE.TR_TR,
  // LOCALE.KO_KR,
  // LOCALE.ID_ID,
  // LOCALE.ES_PE,
  // LOCALE.ES_ES,
  // LOCALE.IT_IT,
  // LOCALE.JA_JP,
  // LOCALE.PT_BR,
  LOCALE.SA_IN,
] // 目前有支援的語系 // default 是給根用的

// 因為 next.config 也要用，所以只能用 es5 寫法
module.exports = { DEFAULT_LOCALE, allowLocale, LOCALE, LOCALE_LABEL, LANGUAGE_LIST, LOCALE_CODE, chineseLocale }
