import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useAppSelector } from '@/redux/hooks'
import { isEmpty } from 'lodash'

// Libs
import handleRoutePushWithQuery from '@/methods/url/handleRoutePushWithQuery'
import getSearchParams from '@/methods/url/getSearchParams'
import removeSearchParams from '@/methods/url/removeSearchParams'

// Constants
import { allowLocale, DEFAULT_LOCALE } from '@/constants/defaultLocale'
import { ROUTE_PATH } from '@/constants/routePath'

export const useUserLocale = () => {
  const locationData = useAppSelector((state) => state.common.locationData)

  const router = useRouter()

  const { pathname, locale, asPath, query } = router

  const { _dittoLocale: userLocale, _dittoCountryCode: countryCode } = locationData

  const searchParams = getSearchParams()

  const localeByUrl = searchParams?.locale?.toLowerCase().replace('_', '-') // 站外的locale格式會帶 xx_xx, 要轉成 xx-xx

  // 如果 url 有帶 locale 就優先用，否則用 ditto(存在 redux) 的 locale
  // 頁面跳轉後會轉到對應的 locale route，並把 url locale 拿掉
  // 此時就要相信 locale route 是正確的 locale，未來導頁也要帶成 url locale 出去
  const newLocale = localeByUrl || userLocale?.toLowerCase().replace('_', '-')

  const isUrlLocaleNotMatch = localeByUrl !== locale?.toLowerCase()

  useEffect(() => {
    if ((localeByUrl && isUrlLocaleNotMatch) || (newLocale && locale === 'default') || localeByUrl === locale) {
      const isAllowLocale = allowLocale.indexOf(newLocale) > -1

      const newUrl = removeSearchParams({ removeKeyList: ['locale'] })

      // 把 locale 以外的 query string 保留
      const newQueryObject = { ...query }
      delete newQueryObject.locale
      //

      const newAsPath = asPath.split('?')[0] // 這裡的 asPath 要把 query string 拿掉

      if (pathname === ROUTE_PATH.NOT_FOUND)
        handleRoutePushWithQuery({
          url: newUrl,
          router,
          pathname: ROUTE_PATH.HOME,
          locale: isAllowLocale ? newLocale : DEFAULT_LOCALE,
          isReplace: true,
          query: newQueryObject,
          shallow: false,
        })
      else if ((locale === 'default' || isEmpty(locale)) && !isEmpty(countryCode)) {
        // 這區塊設定預設語系
        // 如果網址當中沒有指定語系，則根據 country code 設定指定預設語系
        let defaultLocale = 'en-us'

        switch (countryCode) {
          case 'TW':
            defaultLocale = 'zh-tw'
            break
          case 'HK':
            defaultLocale = 'en-hk'
            break
          case 'KR':
            defaultLocale = 'ko-kr'
            break
          default:
            defaultLocale = 'en-hk'
            break
        }

        handleRoutePushWithQuery({
          url: newUrl,
          router,
          pathname: newAsPath,
          locale: defaultLocale,
          isReplace: true,
          query: newQueryObject,
          shallow: false,
        })
      } else if (isAllowLocale) {
        handleRoutePushWithQuery({
          url: newUrl,
          router,
          pathname: newAsPath,
          locale: newLocale,
          isReplace: true,
          query: newQueryObject,
          shallow: false,
        })
      } else {
        handleRoutePushWithQuery({
          url: newUrl,
          router,
          pathname: newAsPath,
          locale: DEFAULT_LOCALE,
          isReplace: true,
          query: newQueryObject,
          shallow: false,
        })
      }
    }
  }, [newLocale, locale, asPath, isUrlLocaleNotMatch, localeByUrl, countryCode]) // 不要加 router
}
